import Swiper from 'swiper/swiper-bundle.min';
import anime from 'animejs';
import ScrollMagic from 'scrollmagic';
import {TweenMax} from "gsap";
import {ScrollMagicPluginGsap} from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, TweenMax);
TweenMax.defaultOverwrite = false;

export function index(controller , scene){
	const fv = document.getElementById('firstview');
	if(fv){
		setTimeout(function(){
			fv.classList.add('active');
			document.getElementById('site-header').classList.add('fvs');
		},400);
        scene.push(
            new ScrollMagic.Scene({tweenChanges: false, triggerElement: "#business", duration: '40%', offset: '-50%'})
                .setTween("#firstview", { opacity: 0 })
                .addTo(controller)
        );
	}
	
    const swiper = new Swiper('#workslider', {
		slidesPerView: 'auto',
    	spaceBetween: 40,
     	centeredSlides: true,
		grabCursor: true,
		loop: true,
    });
};