import Vue from 'vue/dist/vue.min';
import axios from 'axios';

export function vm(){
	const head_vue = new Vue({
		el: '#site-header',
		data: {
			view: false
		}
	});
	
	const wrap_vue = new Vue({
		el: '#contact',
		data: {
            sitename: '株式会社セイリョウ',
			contact_flag: true,
			send_flag: false,
            entry: {
                name: '',
                furigana: '',
                tel: '',
                email: '',
                context: '',
            }
        },
		methods: {
			validate: function(){
                this.contact_flag = true;
				if(!this.entry.email.match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)){
					this.contact_flag = false;
                }else if(this.entry.company == '' | this.entry.name == '' | this.entry.email == '' | this.entry.context == ''){
					this.contact_flag = false;
                }
                if(this.contact_flag){
                    this.send_flag = true;
                }
			},
			mailsend: function(){
                if(this.send_flag){
                    const post_url = '/mail/post.php';
                    const $_this = this;
                    axios.post(post_url , $_this.entry)
                    .then(response => {
                        if(response.data == 'success'){
                            location.href = "/send/";
                        }
                    }).catch(error => {
                        console.log(error);
                    });
                }else{
                    this.contact_flag = false;
                }
			},
		},
	});
};